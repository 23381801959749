import React, { useState, useEffect } from "react";
import {
  Modal,
  Text,
  Button,
  Stack,
  Loader,
  Image,
  Box,
  Center,
  Input,
  Space,
  Alert,
  Notification,
  em,
  LoadingOverlay,
} from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import WineLogo from "../assets/wine_logo.png";
import VXIcon from "../assets/vx-logo.svg";
import FintechLogo from "../assets/fintech_logo.png";
import Acme from "../assets/acme-logo.png";
import { useGlobal } from "../context/global-context";
import { IconExclamationCircle } from "@tabler/icons-react";
import { IconExclamationMark } from "@tabler/icons-react";
import "./verification-modal.css";
export default function VerificationModal({
  title,
  opened,
  close,
  description,
  skip,
}) {
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isCollecting, setIsCollecting] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [email, setEmail] = useState("");
  const [requestId, setRequestId] = useState("");

  const [avChoices, setAvChoices] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Use useLocation
  const { globalState, setContextValue, updateCustomerFields } = useGlobal();

  const handleCustomerDataChange = (dataKey, value = null) => {
    console.log("the value is ", value);
    const dataField = globalState.customerData[dataKey];
    updateCustomerFields(dataKey, {
      ...dataField,
      value: value,
    });
  };

  const logo =
    location.pathname === "/" || location.pathname.includes("wine")
      ? WineLogo
      : location.pathname.includes("fintech")
      ? FintechLogo
      : Acme;

  const fetchLink = async () => {
    setIsLoading(true);
    setError("");
    setLink(""); // Reset link on new fetch

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/demo/dcams/generate-link.php`,
        //"https://164cb3aa52c3.ngrok.app/demo/dcams/generate-link.php",
        {
          method: "POST",

          body: JSON.stringify({
            journey: globalState.journey,
            age: globalState.age,
            choices: globalState.modularChoices,
            state: globalState,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.email) {
        setEmail(data.email);
      }
      if(data?.request_id){
        setRequestId(data.request_id);
      }
      if (data.link && globalState.method === "popup") {
        //window.location.href = `http://localhost:3000/${data.request_id}`;
        setLink(`${process.env.REACT_APP_FRONTEND_URL}/${data.request_id}`);
        //setLink(`http://localhost:3000/${data.request_id}`);
      }
      if (data.link && globalState.method === "redirect") {
        window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${data.request_id}`;
      }
      //setLink(data.link); // Assuming the response has a 'link' property
      //setLink(
      //"https://frontend.dcams.app/?request_id=30f0e74e-6f3b-482e-8d1e-272000e91c00"
      // );
    } catch (error) {
      setError("Failed to fetch data: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("event in the verification modal", event);
      // Check for a specific message
      if (event.data === "CLOSE") {
        setLink("");
        close(); // Close the modal
      }
      if (event.data === "PASS" || event.data === "FAIL") {
        close();
        const path = `/result/${event.data}?request_id=${encodeURIComponent(requestId)}`;
        setContextValue("selectedClientApp", path);
      }
    };

    // Add event listener
    window.addEventListener("message", handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [close, email]);

  useEffect(() => {
    if (opened) {
      setContextValue("isSidebarShown", false);
    } else {
      setContextValue("isSidebarShown", true);
    }

    if (skip) {
      fetchLink();
    }

    if (globalState.isCollectCustomerData) {
      const checkedOptions = Object.entries(globalState.customerData).filter(
        ([key, { checked }]) => checked
      );
      console.log("here are the checked options", checkedOptions);
      if (checkedOptions.length === 0) {
        setIsCollecting(false);
      } else {
        setIsCollecting(true);
      }
    }
  }, [
    opened,
    skip,
    globalState.isCollectCustomerData,
    globalState.customerData,
  ]);

  const handleClose = () => {
    setContextValue("isSidebarShown", true);
    setLink(""); // Invalidate the link
    close(); // Close the modal
  };

  if (skip && !link) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <div className={`veratad-modal ${opened ? "veratad-modal--visible" : ""}`}>
      <div className="veratad-modal-box">
        {!link && isCollecting && !skip && (
          <Notification
            title="Demo Detail"
            //icon={<IconExclamationMark />}
            withBorder
            color="teal"
            withCloseButton={false}
            mb="lg"
            styles={{
              root: {
                //outlineOffset: 0,
              },
            }}
          >
            This example demonstrates the collection of customer data before
            initiating a VX session
          </Notification>
        )}

        {!link && !skip && (
          <>
            <div
              style={{
                position: "absolute",
                bottom: 255,
                width: "100%",
                align: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between", // This will push the button to the bottom
                  height: "100%", // This should take the full height of its parent
                  padding: "25px",
                }}
              >
                <Box>
                  <Stack gap={8} m="md" style={{ backgroundColor: "white" }}>
                    <Center>
                      <Image src={logo} w="75px" fit="contain" />
                    </Center>
                    <Text fz={25} fw={700} align="center">
                      {title}
                    </Text>
                    <Text c="dimmed" align="center">
                      {description}
                    </Text>
                  </Stack>

                  {globalState.isCollectCustomerData && (
                    <Stack gap="md" style={{ backgroundColor: "white" }}>
                      {
                        // Filter checked items first, then map over them
                        Object.entries(globalState.customerData)
                          .filter(([key, { checked }]) => checked)
                          .map(
                            (
                              [key, { label, checked }],
                              index,
                              filteredArray
                            ) => (
                              <React.Fragment key={key}>
                                <Input.Wrapper>
                                  <Input
                                    styles={{
                                      input: {
                                        backgroundColor: "#fafafa",
                                        outline: "1px solid #fafafa",
                                      },
                                    }}
                                    name={key}
                                    placeholder={label}
                                    onChange={(e) =>
                                      handleCustomerDataChange(
                                        key,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Input.Wrapper>
                              </React.Fragment>
                            )
                          )
                      }
                    </Stack>
                  )}
                </Box>
                <Box>
                  <Button
                    mt="xl"
                    size="lg"
                    fullWidth
                    onClick={fetchLink}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Loader size="sm" />
                    ) : (
                      "Continue to Verification Step"
                    )}
                  </Button>
                </Box>
              </div>
            </div>
          </>
        )}

        {error && <Text c="red">{error}</Text>}
        {link && (
          <>
            <iframe
              id="veratad-frame"
              src={link}
              title="Verification"
              allow="camera;"
            ></iframe>
          </>
        )}
      </div>
    </div>
  );
}
